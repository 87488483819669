import React, { useState, useEffect } from "react";
// import Card from "@material-ui/core/Card";
import AWS from "aws-sdk";
/*import {
  ListObjectsCommand,
  ListObjectsCommandOutput,
  S3Client,
} from "@aws-sdk/client-s3"; */
// import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Col, Row } from 'react-bootstrap';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

AWS.config.update({
    accessKeyId: "AKIAVAFNIP2KUNF4BGHU",
    secretAccessKey: "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",
    region: "ap-south-1",
});

const s3 = new AWS.S3();

const params = {
    Bucket: 'dbraei',
    Delimiter: '',
    Prefix: 'lastyears/',
};


const Naacuplaod = props => {

    // Create state to store file
    const [file, setFile] = useState(null);
    const [depatmentdata, setdepatmentdata] = useState();
    const [yeardata, setyeardata] = useState();
    const [listFiles, setListFiles] = useState([]);
    const [doctitle, setdoctitle] = useState();

    const Years_Data = [

        { label: '2024-2025' },
        { label: '2023-2024' },
        { label: '2022-2023' },
        { label: '2021-2022' },
        { label: '2020-2021' },
        { label: '2019-2020' },
        { label: '2018-2019' },
        { label: '2018-2017' },
        { label: '2016-2017' },
        { label: '2015-2016' }

    ]

    const Deparment_Data = [

        { label: 'BA' },
        { label: 'BBA' },
        { label: 'BCom' },
        { label: 'BSC' },
        { label: 'Languages' },
        { label: 'Dr B R AMBEDKAR COLLEGE' },

    ]

    const Table_Haeders = [

        { id: 'Academic_Year', label: 'Academic Year', minWidth: 170 },
        { id: 'Department', label: 'Department', minWidth: 170 },
        { id: 'Key', label: 'NAAC Document File', minWidth: 170 },
        { id: 'Url_link', label: 'Action', minWidth: 170  }

    ]
                                                   
    useEffect(() => {
      
    }, []);

    // const getList = () => {
    //     console.log(yeardata)
    //     const params = {
    //         Bucket: 'dbraei',
    //         Delimiter: '',
    //         Prefix: `${yeardata}/${depatmentdata}/`,
    //     };

    //     s3.listObjectsV2(params, (err, data) => {
    //         if (err) {
    //             console.log(err, err.stack);
    //         } else {
    //             // let updateddata = [];
    //             for(let i=0; i < data.Contents.length; i++ ){
    //                 data.Contents[i].Academic_Year = yeardata;
    //                 data.Contents[i].Department = depatmentdata;
    //             }
    //             setListFiles(data.Contents);
    //             console.log(listFiles);
    //             // setListFiles(updateddata);
    //         }
    //     });
    // }

        const getSignedURLEndpoint = (operation, options) => {
        const url = s3.getSignedUrl(operation, options);
        return url;
    }

    const getList = () =>{
        console.log(yeardata)
        const params = {
            Bucket: 'dbraei',
            Delimiter: '',
            Prefix: `${yeardata}/${depatmentdata}/`,
        };

        s3.listObjectsV2(params, (err, data) => {
            if (err) {
                console.log(err, err.stack);
            } else {
                // let updatedFiles = [];
            data.Contents.forEach(element => {
            let len = element.Key.length;
            console.log("Element: \n" + element);
            if(element.Key[len-1] !== '/') {
            //    updatedFiles.push(element);
               console.log(element)
               let url = getSignedURLEndpoint('getObject', {Bucket: 'dbraei', Key: element.Key, Expires: 3600})
               //urls.push(url);
            //    for(let i=0; i < data.Contents.length; i++ ){
                element.Academic_Year = yeardata;
                element.Department = depatmentdata;
                element.Url_link = url;
                // }
                console.log(data.Contents)
                setListFiles(data.Contents);
               console.log(url);
            }
        })
                // let updateddata = [];
                
                // console.log(updatedFiles);
                //  setListFiles(updatedFiles);
            }
        });

    }
    // Function to handle file and store it to file state
    const handleFileChange = (e) => {
        // Uploaded file
        //console.log("Handling file change");
        const file = e.target.files[0];
        // Changing file state
        setFile(file);
    };

    const uploadFile = async () => {
        // S3 Bucket Name
        // const S3_BUCKET = "bucket-name";

        // // S3 Region
        // const REGION = "region";

        // // S3 Credentials
        // AWS.config.update({
        //     accessKeyId: "Key ID",
        //     secretAccessKey: "Access Key",
        // });
        // const s3 = new AWS.S3({
        //     params: { Bucket: S3_BUCKET },
        //     region: REGION,
        // });

        // Files Parameters
        const uploadparams = {
            Bucket: 'dbraei',
            // dirName: '2015-2016/BA/', 
            // Delimiter: '',
            // Prefix: 'lastyears/',
            Key: yeardata + '/' + depatmentdata + '/' + file.name,
            Body: file,
        };

        // Uploading file to s3
        var upload = s3
            .putObject(uploadparams)
            .on("httpUploadProgress", (evt) => {
                // File uploading progress
                console.log(
                    "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                );
            })
            .promise();
        await upload.then((err, data) => {
            console.log(err);
            // Fille successfully uploaded
            alert("File uploaded successfully.");
            resetForm();
        });
    };

    const resetForm = () => {
        console.log("reset working")
        setdepatmentdata('');
        setyeardata('');
        setdoctitle('');
        setFile('');
    }

    return (
        <div>
            <div className="container">
                <div className="mt-5">
                    <h3> Add NAAC Document </h3>
                </div>
                <div className="Dropdown mt-3">
                    <Row>
                        <Col sm={6} className="mb-3">
                            <FormControl fullWidth>
                                <InputLabel id="Academicyear">Academic Years</InputLabel>
                                <Select
                                    labelId="Academicyear"
                                    id="Academicyear-select"
                                    value={yeardata}
                                    label="Academic Years"
                                    onChange={(e) => { setyeardata(e.target.value); console.log(e.target.value) }}
                                >
                                    {Years_Data.map((yeardataa) =>
                                        <MenuItem value={yeardataa.label}>{yeardataa.label}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            {/* <Autocomplete
                                disablePortal
                                id="Academicyear"
                                options={Years_Data}
                                value={yeardata}
                                onChange={(e) => { setyeardata(e.target.innerText); console.log(e.target.innerText) }}
                                renderInput={(params) => <TextField {...params} label="Years" onChange={(e) => console.log(e)} />}
                            /> */}
                        </Col>
                        <Col sm={6} className="mb-3">
                            <FormControl fullWidth>
                                <InputLabel id="Deparment">Deparment</InputLabel>
                                <Select
                                    labelId="Deparment"
                                    id="Deparment-select"
                                    value={depatmentdata}
                                    label="Deparment"
                                    onChange={(e) => {setdepatmentdata(e.target.value); console.log(e.target.value)}}
                                >
                                    {Deparment_Data.map((depatmentdataa) =>
                                        <MenuItem value={depatmentdataa.label}>{depatmentdataa.label}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            {/* <Autocomplete
                                disablePortal
                                id="Deparment"
                                options={Deparment_Data}
                                value={depatmentdata || ''}
                                onChange={(e) => { setdepatmentdata(e.target.value); console.log(e.target.value) }}
                                renderInput={(params) => <TextField {...params} label="Department" />}
                            /> */}
                        </Col>
                        <Col sm={6} className="mb-3">
                            <TextField id="doctitle" className='w-100'
                                type="text"
                                label="Document Title"
                                value={doctitle}
                                onChange={(e) => setdoctitle(e.target.value)}
                            />
                        </Col>
                        <Col sm={6} className="mb-3">
                            <TextField id="file" className='w-100'
                                type="file"
                                onChange={handleFileChange} />
                        </Col>
                    </Row>

                </div>
                <Stack spacing={2} direction="row" className="d-flex justify-content-end">
                    <div className='mt-2'>
                        {/* <input type="file" onChange={handleFileChange} /> */}
                        <Button variant="outlined" onClick={uploadFile}>Upload</Button>
                        {/* <Button variant="outlined" >Upload</Button> */}
                    </div>
                    <div className='mt-2'>
                        {/* <Button variant="outlined" >GetList</Button> */}
                        <Button variant="outlined" onClick={getList}>GetList</Button>
                    </div>
                </Stack>
                <Stack className="mt-3">
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {Table_Haeders.map((Table_Haeder) => (
                                        <TableCell
                                            key={Table_Haeder.id}
                                            align={Table_Haeder.align}
                                            style={{ minWidth: Table_Haeder.minWidth }}
                                        >
                                            {Table_Haeder.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listFiles
                                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                {Table_Haeders.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.id == 'Url_link' 
                                                                ? <a href={value}>View</a>
                                                                : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            </div>
            <div>

            </div>

        </div>
    );
}
export default Naacuplaod  